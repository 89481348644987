import React from "react";
import moment from "moment";
import { Field, reduxForm, change } from "redux-form";
import { Alert, Button, DatePicker, TimePicker, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Input from "../../../../form-components/Input";
import Checkbox from "../../../../form-components/Checkbox";
import { makeField } from "../../../../form-components/makeComponent";
import MultipleSelect from "../../../../form-components/MultipleSelect";
import CustomSelect from "../../../../form-components/CustomSelect";
import vod from "../../../../../apis/vod";
import FileField from "../../../../form-components/FileField";
import createFormData from "../../../../../helpers/createFormData";

class PacketForm extends React.Component {
  state = {
    mobile_image: this.props.mobile_image,
    tablet_image: this.props.tablet_image,
    desktop_image: this.props.desktop_image,
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.mobile_image !== prevProps.mobile_image)
      this.setState({ mobile_image: this.props.mobile_image });
    if (this.props.tablet_image !== prevProps.tablet_image)
      this.setState({ tablet_image: this.props.tablet_image });
    if (this.props.desktop_image !== prevProps.desktop_image)
      this.setState({ desktop_image: this.props.desktop_image });
  }
  setImageUrl = (url, fieldName) => {
    const newStateValue = {};
    newStateValue[fieldName] = url;
    this.setState(newStateValue);
  };
  deleteImageFromServer = (urlField) => {
    vod
      .delete(`/api/packets/${this.props.packetId}/image/${urlField}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.props.dispatch(change("packetForm", urlField, ""));
      })
      .catch((err) => {
        console.error("Wystąpił błąd: " + err);
      });
  };
  onSubmit = async (formValues) => {
    formValues.events = JSON.stringify(this.getSelectedEvents());
    formValues.videos = JSON.stringify(this.getSelectedVideos());

    const formData = await createFormData(
      [
        "events",
        "videos",
        "description",
        "title",
        "price",
        "exp_date",
        "published",
        "force_visibility",
        "restricted_access",
        "age_restriction",
        "mobile_image",
        "tablet_image",
        "desktop_image",
        "mobile_image_data",
        "tablet_image_data",
        "desktop_image_data",
      ],
      [
        {
          name: "mobile_image_data",
          options: {
            maxSizeMB: 1,
            maxWidthOrHeight: 1000,
          },
        },
        {
          name: "tablet_image_data",
          options: {
            maxSizeMB: 2,
            maxWidthOrHeight: 1500,
          },
        },
        {
          name: "desktop_image_data",
          options: {
            maxSizeMB: 3,
            maxWidthOrHeight: 3000,
          },
        },
      ],
      formValues
    );
    this.props.onSubmit(formData);
  };
  getSelectedEvents = () => {
    const selectedEvents = document.querySelectorAll(
      ".eventSelect .ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content"
    );
    let eventsArray = [];
    for (let i = 0; i < selectedEvents.length; i++) {
      const name = selectedEvents[i].innerHTML;
      const eventId = name.substr(
        name.lastIndexOf("(") + 1,
        name.lastIndexOf(")") - 1
      );
      if (eventId !== null) eventsArray.push(parseInt(eventId));
    }

    return eventsArray.filter(
      (item, index) => eventsArray.indexOf(item) === index
    );
  };
  getSelectedVideos = () => {
    const selectedVideos = document.querySelectorAll(
      ".videoSelect .ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content"
    );
    let videosArray = [];
    for (let i = 0; i < selectedVideos.length; i++) {
      const name = selectedVideos[i].innerHTML;
      const videoId = name.substr(
        name.lastIndexOf("(") + 1,
        name.lastIndexOf(")") - 1
      );
      if (videoId !== null) videosArray.push(parseInt(videoId));
    }
    return videosArray.filter(
      (item, index) => videosArray.indexOf(item) === index
    );
  };
  getRestrictedAccessSlug = (val) => {
    switch (val) {
      case "any":
        return "świat";
      case "poland":
        return "pl";
      case "europe":
        return "eu";
      default:
        return null;
    }
  };
  render() {
    const error = this.props.error;
    let defaultEventsValue = this.props.initialEvents
      ? this.props.initialEvents.map((eventId) => {
          const eventObj = this.props.events.find((o) => o.id === eventId);
          if (eventObj)
            return (
              eventObj.title +
              (!!eventObj.age_restriction
                ? ` (+${eventObj.age_restriction})`
                : "") +
              (!!eventObj.restricted_access
                ? ` (${this.getRestrictedAccessSlug(
                    eventObj.restricted_access
                  )})`
                : "") +
              " (" +
              eventObj.id +
              ")"
            );
          return undefined;
        })
      : [];
    let defaultVideosValue = this.props.initialVideos
      ? this.props.initialVideos.map((videoId) => {
          const videoObj = this.props.videos.find((o) => o.id === videoId);
          if (videoObj)
            return (
              videoObj.title +
              (!!videoObj.age_restriction
                ? ` (+${videoObj.age_restriction})`
                : "") +
              (!!videoObj.restricted_access
                ? ` (${this.getRestrictedAccessSlug(
                    videoObj.restricted_access
                  )})`
                : "") +
              " (" +
              videoObj.id +
              ")"
            );
          return undefined;
        })
      : [];
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Field
          name="title"
          component={Input}
          placeholder="Tytuł pakietu"
          type="text"
        />
        <Field
          name="description"
          placeholder="Opis pakietu (kod html)"
          component="textarea"
        />
        <Field
          name="price"
          component={Input}
          placeholder="Cena"
          suffix={" zł"}
          type="number"
          step="0.01"
          style={{ width: "150px" }}
        />
        <Field
          className="datePicker"
          showTime
          name="exp_date"
          component={makeField(DatePicker, "HH:mm DD.MM.YYYY", false)}
          label={"Dostępny do:"}
          hasFeedback
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
        />
        <Field
          name="videos"
          component={MultipleSelect}
          options={this.props.videos.map((v) => {
            v.name =
              v.title +
              (!!v.age_restriction ? ` (+${v.age_restriction})` : "") +
              (!!v.restricted_access
                ? ` (${this.getRestrictedAccessSlug(v.restricted_access)})`
                : "");
            return v;
          })}
          className="videoSelect"
          label="Treści VOD w pakiecie"
          placeholder="Wybierz treści VOD"
          defaultValue={defaultVideosValue}
        />
        <Field
          name="events"
          component={MultipleSelect}
          options={this.props.events.map((v) => {
            v.name =
              v.title +
              (!!v.age_restriction ? ` (+${v.age_restriction})` : "") +
              (!!v.restricted_access
                ? ` (${this.getRestrictedAccessSlug(v.restricted_access)})`
                : "");
            return v;
          })}
          className="eventSelect"
          label="Wydarzenia w pakiecie"
          placeholder="Wybierz wydarzenia"
          defaultValue={defaultEventsValue}
        />
        <label className="access-select" htmlFor="selectRestrictedAccess">
          {this.props.syncErrors && this.props.syncErrors.restricted_access
            ? "ERROR"
            : null}
          <Field
            onChange={(e) => {
              this.props.change(
                "restricted_access",
                e.target.options[e.target.selectedIndex].text
              );
            }}
            id="selectRestrictedAccess"
            name="restricted_access"
            label="Ogranicz dostęp"
            component={CustomSelect}
          >
            <option value="">Niezdefiniowany</option>
            <option value="any">Świat</option>
            <option value="poland">Polska</option>
            <option value="europe">Unia Europejska</option>
          </Field>
        </label>
        <Field
          name="age_restriction"
          component={Input}
          label="Min. wiek użytkownika"
          placeholder="np. 18"
          suffix={" lat"}
          type="number"
          step="1"
          style={{ width: "180px" }}
        />
        <FileField
          setImageUrl={this.setImageUrl}
          name="mobile_image_data"
          urlField="mobile_image"
          label="Zdjęcie wyświetlane na urządzeniach mobilnych"
          deleteImageFromServer={this.deleteImageFromServer}
          currentImage={this.state.mobile_image}
          maxWidth={1000}
          maxHeight={1000}
          maxWeight={1200}
        />
        <FileField
          setImageUrl={this.setImageUrl}
          name="tablet_image_data"
          urlField="tablet_image"
          label="Zdjęcie wyświetlane na tabletach"
          deleteImageFromServer={this.deleteImageFromServer}
          currentImage={this.state.tablet_image}
          maxWidth={1500}
          maxHeight={1500}
          maxWeight={2200}
        />
        <FileField
          setImageUrl={this.setImageUrl}
          name="desktop_image_data"
          urlField="desktop_image"
          label="Zdjęcie wyświetlane na komputerach"
          deleteImageFromServer={this.deleteImageFromServer}
          currentImage={this.state.desktop_image}
          maxWidth={3000}
          maxHeight={3000}
          maxWeight={3200}
        />
        <Field
          name="force_visibility"
          component={Checkbox}
          type="checkbox"
          label="Wymuś wyświetlenie pakietu"
          suffix={
            <Tooltip
              style={{ margin: "0 8px", color: "blue" }}
              title="Zaznacz jeżeli pakiet ma być widoczny mimo, że część treści jest niedostępna (np. zakończone wydarzenie lub nieopublikowana treść VOD)"
            >
              <InfoCircleOutlined
                className="site-form-item-icon"
                style={{ color: "rgba(0,0,0,.45)" }}
              />
            </Tooltip>
          }
          size="big"
        />
        <Field
          name="published"
          component={Checkbox}
          type="checkbox"
          label="Opublikowany"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli pakiet ma być dostępne dla użytkowników">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        {error ? <Alert message={error} type="error" showIcon /> : null}
        <br />
        <Button type="primary" htmlType="submit" block="true">
          {this.props.mode === "add" ? "Dodaj" : "Zaktualizuj"}
        </Button>
      </form>
    );
  }
}

const validate = (formValues, props) => {
  const errors = {};

  if (!formValues.title) errors.title = "Proszę podać tytuł.";
  if (!formValues.description) errors.description = "Proszę podać odnośnik.";
  if (!formValues.price || parseFloat(formValues.price) == 0)
    errors.price = "Proszę podać cenę.";

  if (formValues.title && formValues.title.length > 100)
    errors.title = "Maksymalnie 100 znaków.";

  if (
    formValues.price !== undefined &&
    !/^\d+(.\d{1,2})?$/.test(formValues.price)
  )
    errors.price = "Niepoprawna wartość";

  if (
    formValues.age_restriction !== undefined &&
    formValues.age_restriction !== null &&
    formValues.age_restriction !== "" &&
    isNaN(parseInt(formValues.age_restriction))
  )
    errors.age_restriction = "Niepoprawna wartość";

  if (!formValues.exp_date || !moment(formValues.exp_date).isValid())
    errors.exp_date = "Nieprawidłowa data.";
  return errors;
};

export default reduxForm({
  form: "packetForm",
  enableReinitialize: true,
  validate,
})(PacketForm);
