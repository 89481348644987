import React from "react";
import moment from "moment";
import { Field, reduxForm, change, updateSyncErrors } from "redux-form";
import { Alert, Tooltip, Button, Select, Row, Col, DatePicker } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import vod from "../../../../../apis/vod";
import { renderPreview } from "../../../../../helpers/renderPreview";
import createFormData from "../../../../../helpers/createFormData";
import Checkbox from "../../../../form-components/Checkbox";
import MultipleSelect from "../../../../form-components/MultipleSelect";
import { makeField } from "../../../../form-components/makeComponent";
import FileField from "../../../../form-components/FileField";
import Input from "../../../../form-components/Input";
import LineBreak from "../../../../LineBreak";
import CustomSelect from "../../../../form-components/CustomSelect";
import slugify from "../../../../../helpers/slugify";
import { linkRepertoireIntegration } from "../../../../../theme-variables";

class VideoForm extends React.Component {
  state = {
    video: this.props.initialValues.video_link,
    teaser: this.props.initialValues.teaser_link,
    image: this.props.initialValues.image_link,
    thumb: this.props.initialValues.thumb_link,
    autodesc: this.props.initialValues.autodesc_video,
    accessibility: this.props.initialValues.accessibility_video,
  };

  getSelectedCategories = () => {
    const selectedCategories = document.querySelectorAll(
      ".ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content"
    );
    let categoriesArray = [];
    for (let i = 0; i < selectedCategories.length; i++) {
      const name = selectedCategories[i].innerHTML;
      const categoryId = name.substr(
        name.lastIndexOf("(") + 1,
        name.lastIndexOf(")") - 1
      );
      if (categoryId !== null) categoriesArray.push(parseInt(categoryId));
    }
    return categoriesArray;
  };

  onSubmit = async (formValues) => {
    formValues.categories = JSON.stringify(this.getSelectedCategories());

    const formData = await createFormData(
      [
        "slug",
        "categories",
        "description",
        "additional_desc",
        "teaser_link",
        "weight",
        "title",
        "subtitle",
        "age_restriction",
        "duration",
        "gallery_id",
        "available_from",
        "video_link",
        "video_price",
        "accessibility_video",
        "autodesc_video",
        "dashboard_hidden",
        "visible",
        "image_link",
        "main_image",
        "thumb_link",
        "thumb_image",
        "restricted_access",
        "end_date",
        "tickets_expiration_time",
        "share_teatrlink",
        "auth_required",
        "mailing_free",
      ],
      [
        {
          name: "main_image",
          options: {
            maxSizeMB: 3,
            maxWidthOrHeight: 2000,
            useWebWorker: true,
          },
        },
        {
          name: "thumb_image",
          options: {
            maxSizeMB: 1,
            maxWidthOrHeight: 650,
            useWebWorker: true,
          },
        },
      ],
      formValues
    );
    this.props.onSubmit(formData);
  };

  setImageUrl = (url, fieldName) => {
    const newStateValue = {};
    newStateValue[fieldName] = url;
    this.setState(newStateValue);
  };

  deleteImageFromServer = (urlField) => {
    vod
      .delete(`/api/videos/${this.props.videoId}/image/${urlField}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.props.dispatch(change("videoForm", urlField, ""));
        this.props.dispatch(change("videoForm", `${urlField}_link`, ""));
      })
      .catch((err) => {
        console.error("Wystąpił błąd: " + err);
      });
  };

  handleVideoChange = (e) => {
    this.setState({ video: e.target.value });
  };
  handleAccessibilityVideoChange = (e) => {
    this.setState({ accessibility: e.target.value });
  };
  handleAutodescVideo = (e) => {
    this.setState({ autodesc: e.target.value });
  };
  handleTeaserChange = (e) => {
    this.setState({ teaser: e.target.value });
  };
  onVideoSelectChange = (v) => {
    this.handleVideoChange({ target: { value: v } });
    this.props.dispatch(change("videoForm", "video_link", v));
  };
  onAccessibilityVideoSelectChange = (v) => {
    this.handleAccessibilityVideoChange({ target: { value: v } });
    this.props.dispatch(change("videoForm", "accessibility_video", v));
  };
  onAutodescVideoChange = (v) => {
    this.handleAutodescVideo({ target: { value: v } });
    this.props.dispatch(change("videoForm", "autodesc_video", v));
  };
  onTeaserSelectChange = (v) => {
    this.handleTeaserChange({ target: { value: v } });
    this.props.dispatch(change("videoForm", "teaser_link", v));
  };
  renderSelect = (func) => {
    return (
      <Select
        defaultValue=""
        allowClear
        style={{ width: "100%" }}
        showSearch
        dropdownMatchSelectWidth={false}
        onChange={func}
      >
        {this.props.vimeoVideos.map((v) => (
          <Select.Option
            key={v.uri}
            value={v.iframe_code || v.uri}
            disabled={v.status === "NOT_UPLOADED" ? true : false}
          >
            {v.name}
          </Select.Option>
        ))}
      </Select>
    );
  };
  render() {
    let defaultValue = this.props.initialCategories
      ? this.props.initialCategories.map((categoryId) => {
          const categoryObj = this.props.options.find(
            (o) => o.id === categoryId
          );
          if (categoryObj) return `${categoryObj.name} (${categoryObj.id})`;
          return undefined;
        })
      : [];
    const error = this.props.error;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Field
          name="title"
          component={Input}
          placeholder="Tytuł wideo (wymagane)"
          onChange={(e) => {
            if (!this.props.initialValues.slug)
              this.props.dispatch(
                change(
                  "videoForm",
                  "slug",
                  slugify(e.target.value).substr(0, 40)
                )
              );
          }}
          type="text"
        />
        <Field
          name="slug"
          component={Input}
          placeholder="Odnośnik"
          type="text"
          suffix={
            <Tooltip title="Proszę wpisać końcową wartość odnośnika do zawartości, np. tytul-spektaklu (wideo będzie dostępne za pomocą odnośnika: /wideo/tytul-spektaklu). Prosimy nie modyfikować wartości, jeżeli odnośnik do wideo został udostępniony. Zmiana wartości spowoduje, że poprzedni odnośnik będzie nieaktywny.">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        <Field
          name="subtitle"
          component={Input}
          placeholder="Podtytuł"
          type="text"
        />
        <label htmlFor="videoLink">
          Wideo
          <Row>
            <Col xs={10} style={{ marginRight: 15 }}>
              {this.renderSelect(this.onVideoSelectChange)}
            </Col>
            <Col xs={12}>
              <Field
                id="videoLink"
                name="video_link"
                component={Input}
                placeholder="Kod osadzenia / id filmu"
                type="text"
                onChange={this.handleVideoChange}
                suffix={
                  <Tooltip title="Umieść identyfikator wideo bądź kod iframe. Możesz też wybrać wideo z listy.">
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Col>
          </Row>
        </label>
        <label htmlFor="accessibilityVideo">
          Wideo z tłumaczem PJM
          <Row>
            <Col xs={10} style={{ marginRight: 15 }}>
              {this.renderSelect(this.onAccessibilityVideoSelectChange)}
            </Col>
            <Col xs={12}>
              <Field
                id="accessibilityVideo"
                name="accessibility_video"
                component={Input}
                placeholder="Kod osadzenia / id filmu"
                type="text"
                onChange={this.handleAccessibilityVideoChange}
                suffix={
                  <Tooltip title="Umieść identyfikator wideo bądź kod iframe. Możesz też wybrać wideo z listy.">
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Col>
          </Row>
        </label>
        <label htmlFor="autodescVideo">
          Wideo z polskimi napisami
          <Row>
            <Col xs={10} style={{ marginRight: 15 }}>
              {this.renderSelect(this.onAutodescVideoChange)}
            </Col>
            <Col xs={12}>
              <Field
                id="autodescVideo"
                name="autodesc_video"
                component={Input}
                placeholder="Kod osadzenia / id filmu"
                type="text"
                onChange={this.handleAutodescVideo}
                suffix={
                  <Tooltip title="Umieść identyfikator wideo bądź kod iframe. Możesz też wybrać wideo z listy.">
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Col>
          </Row>
        </label>
        <label htmlFor="teaserLink">
          Teaser
          <Row>
            <Col xs={10} style={{ marginRight: 15 }}>
              {this.renderSelect(this.onTeaserSelectChange)}
            </Col>
            <Col xs={12}>
              <Field
                id="teaserLink"
                name="teaser_link"
                component={Input}
                placeholder="Kod osadzenia / id filmu"
                onChange={this.handleTeaserChange}
                type="text"
                suffix={
                  <Tooltip title="Umieść identyfikator wideo bądź kod iframe. Możesz też wybrać wideo z listy.">
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Col>
          </Row>
        </label>
        <FileField
          name="main_image"
          urlField="image"
          label="Zdjęcie główne"
          setImageUrl={this.setImageUrl}
          deleteImageFromServer={
            this.props.mode !== "add" ? this.deleteImageFromServer : null
          }
          suffix={
            <Tooltip title="Zdjęcie wyświetli się obok opisu, gdy nie zostanie wybrana żadna galeria.">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
          currentImage={this.state.image}
          maxWidth={1000}
          maxHeight={1000}
          maxWeight={1200}
        />
        <FileField
          name="thumb_image"
          urlField="thumb"
          label="Miniaturka"
          setImageUrl={this.setImageUrl}
          deleteImageFromServer={
            this.props.mode !== "add" ? this.deleteImageFromServer : null
          }
          currentImage={this.state.thumb}
          maxWidth={1000}
          maxHeight={1000}
          maxWeight={1200}
        />
        <label className="gallery-select" htmlFor="selectGallery">
          {this.props.syncErrors && this.props.syncErrors.gallery_id
            ? "ERROR"
            : null}
          <Field
            id="selectGallery"
            name="gallery_id"
            label="Wybierz galerię"
            component={CustomSelect}
          >
            <option key="0" value="">
              Brak
            </option>
            {this.props.galleries.map((g) => (
              <option key={g.id} value={g.id}>
                {g.name}
              </option>
            ))}
          </Field>
          <Tooltip title="Galerię należy wcześniej utworzyć w zakładce Galerie w panelu administracyjnym.">
            <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        </label>
        <Field
          name="description"
          placeholder="Opis spektaklu (kod html)"
          component="textarea"
        />
        <Field
          name="additional_desc"
          placeholder="Dodatkowe informacje (kod html)"
          component="textarea"
        />
        <Field
          className="datePicker"
          showTime
          name="available_from"
          component={makeField(DatePicker, "HH:mm DD.MM.YYYY", false)}
          label={"Dostępne od:"}
          hasFeedback
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
        />
        <Field
          className="datePicker"
          showTime
          name="end_date"
          component={makeField(DatePicker, "HH:mm DD.MM.YYYY", false)}
          label={"Dostępne do:"}
          hasFeedback
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
        />
        <Field
          name="duration"
          component={Input}
          placeholder="Czas trwania"
          suffix={" min"}
          type="number"
          style={{ width: "200px" }}
        />
        <Field
          name="video_price"
          component={Input}
          placeholder="Cena"
          suffix={" zł"}
          type="number"
          step="0.01"
          style={{ width: "150px" }}
        />
        <Field
          name="weight"
          component={Input}
          label="Waga"
          placeholder="Waga"
          type="number"
          step="1"
          suffix={
            <Tooltip title="Służy do sortowania wideo na liście. Wideo sortowane są malejąco według wagi, a następnie według tytułu">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
          style={{ width: "150px" }}
        />
        <Field
          name="age_restriction"
          component={Input}
          label="Min. wiek użytkownika"
          placeholder="np. 18"
          suffix={" lat"}
          type="number"
          step="1"
          style={{ width: "180px" }}
        />
        <Field
          name="categories"
          component={MultipleSelect}
          options={this.props.options}
          label="Kategorie filmu"
          placeholder="Wybierz kategorie"
          defaultValue={defaultValue}
          onChange={this.handleCategoriesChange}
        />
        <label className="access-select" htmlFor="selectRestrictedAccess">
          {this.props.syncErrors && this.props.syncErrors.restricted_access
            ? "ERROR"
            : null}
          <Field
            onChange={(e) => {
              this.props.change(
                "restricted_access",
                e.target.options[e.target.selectedIndex].text
              );
            }}
            id="selectRestrictedAccess"
            name="restricted_access"
            label="Ogranicz dostęp"
            component={CustomSelect}
          >
            <option value="">Niezdefiniowany</option>
            <option value="any">Świat</option>
            <option value="poland">Polska</option>
            <option value="europe">Unia Europejska</option>
          </Field>
        </label>
        <label className="access-select" htmlFor="selectTicketsExpirationTime">
          {this.props.syncErrors &&
          this.props.syncErrors.tickets_expiration_time
            ? "ERROR"
            : null}
          <Field
            onChange={(e) => {
              this.props.change(
                "tickets_expiration_time",
                e.target.options[e.target.selectedIndex].text
              );
            }}
            id="selectTicketsExpirationTime"
            name="tickets_expiration_time"
            label="Czas ważności biletu"
            suffix={
              <Tooltip title="Podanie wartości nadpisze ustawienie globalne z zakładki Ograniczenia">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            }
            component={CustomSelect}
          >
            <option key="no-limit" value={"undefined"}>
              Niezdefiniowany
            </option>
            <option key="0" value={0}>
              Bez ograniczeń
            </option>
            <option key="3" value={3}>
              3 godziny
            </option>
            <option key="6" value={6}>
              6 godzin
            </option>
            <option key="12" value={12}>
              12 godzin
            </option>
            <option key="24" value={24}>
              24 godziny
            </option>
            <option key="36" value={36}>
              36 godzin
            </option>
            <option key="48" value={48}>
              48 godzin
            </option>
            <option key="72" value={72}>
              72 godziny
            </option>
          </Field>
        </label>
        <Field
          name="dashboard_hidden"
          component={Checkbox}
          type="checkbox"
          label="Ukryty na stronie głównej"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli wideo ma być ukryte w sekcji VOD na stronie głównej">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        {linkRepertoireIntegration ? (
          <Field
            name="share_teatrlink"
            component={Checkbox}
            type="checkbox"
            label="Udostępnij w teapp.link"
            size="big"
            suffix={
              <Tooltip title="Zaznacz jeżeli chcesz udostępnić w wydarzenie na stronie teapp.link. Skontaktuj się z nami jeżeli chcesz zintegrować Twój system VOD z systemem teapp.link.">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            }
          />
        ) : null}
        <Field
          name="auth_required"
          component={Checkbox}
          type="checkbox"
          label="Dostępne tylko dla zalogowanych użytkowników"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli wideo ma być dostępne tylko dla zalogowanych użytkowników. Jeżeli opcja jest odznaczona oraz dostęp do treści jest darmowy to użytkownicy niezalogowani będa mogli obejrzeć wideo">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        <Field
          name="mailing_free"
          component={Checkbox}
          type="checkbox"
          label="Darmowy dostęp dla użytkowników zapisanych do newslettera"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli treść VOD ma być dostępna za darmo dla użytkowników, którzy zapisali się do newslettera. Jeżeli dostęp do treści jest darmowy to zaznaczenie tej opcji spowoduje, że użytkownicy niezapisani do newslettera nie będą mieli dostępu.">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        <Field
          name="visible"
          component={Checkbox}
          type="checkbox"
          label="Opublikowany w sekcji VOD"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli wideo ma być dostępne dla użytkowników. Pole odznaczone oznacza, że dostęp do wideo będzie miał tylko administrator">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        {error ? <Alert message={error} type="error" showIcon /> : null}
        <br />
        <Button type="primary" htmlType="submit" block="true">
          {this.props.mode === "add" ? "Dodaj" : "Zaktualizuj"}
        </Button>

        <LineBreak />
        <h2>Podgląd wideo</h2>
        {renderPreview(this.state.video)}
        <LineBreak />
        <h2>Podgląd teasera</h2>
        {renderPreview(this.state.teaser)}
      </form>
    );
  }
}

const validate = (formValues, props) => {
  const errors = {};
  if (!formValues.title) errors.title = "Proszę podać nazwę wydarzenia.";
  if (!formValues.slug) errors.slug = "Proszę podać odnośnik.";

  if (formValues.subtitle && formValues.subtitle.length > 200)
    errors.subtitle = "Maksymalnie 200 znaków.";

  if (!formValues.video_link)
    errors.video_link =
      "Proszę podać link do wideo, jego identyfikator lub kod iframe";

  if (
    formValues.slug !== undefined &&
    (!/[a-z0-9\-\_]+$/.test(formValues.slug) ||
      /^[0-9]+$/.test(formValues.slug))
  )
    errors.slug =
      "Niepoprawny odnośnik. Dozwolone małe litery, cyfry oraz myślnik. Wymagana conajmniej jedna litera.";

  if (
    formValues.slug !== undefined &&
    props.videoSlugs.find(
      ({ slug, id }) => slug === formValues.slug && props.videoId !== id
    )
  )
    errors.slug = "Odnośnik zajęty";

  if (formValues.slug !== undefined && formValues.slug.length > 40)
    errors.slug = "Maksymalnie 40 znaków";

  if (formValues.slug !== undefined && !/[a-z0-9\-\_]+$/.test(formValues.slug))
    errors.slug = "Dozwolone tylko małe litery, cyfry i myślnik";

  if (
    formValues.video_price !== undefined &&
    !/^\d+(.\d{1,2})?$/.test(formValues.video_price)
  )
    errors.video_price = "Niepoprawna wartość";

  if (
    formValues.age_restriction !== undefined &&
    formValues.age_restriction !== null &&
    formValues.age_restriction !== "" &&
    isNaN(parseInt(formValues.age_restriction))
  )
    errors.age_restriction = "Niepoprawna wartość";

  if (
    formValues.available_from !== null &&
    !moment(formValues.available_from).isValid()
  )
    errors.available_from = "Nieprawidłowa data.";

  if (formValues.end_date !== null && !moment(formValues.end_date).isValid())
    errors.end_date = "Nieprawidłowa data.";

  if (
    formValues.end_date !== null &&
    formValues.available_from !== null &&
    moment(formValues.end_date).isBefore(moment(formValues.available_from))
  )
    errors.end_date = "Nieprawidłowa data końcowa.";

  if (
    formValues.duration !== null &&
    formValues.duration !== undefined &&
    formValues.duration < 0
  )
    errors.duration = "Nieprawidłowy czas.";

  if (
    formValues.weight !== null &&
    isNaN(formValues.weight) &&
    isNaN(parseInt(formValues.weight))
  )
    errors.weight = "Niepoprawna wartość";

  if (
    ![
      "undefined",
      "0",
      "3",
      "6",
      "12",
      "24",
      "36",
      "48",
      "72",
      0,
      3,
      6,
      12,
      24,
      36,
      48,
      72,
    ].includes(formValues.tickets_expiration_time)
  )
    errors.tickets_expiration_time = "Niepoprawna wartość";

  return errors;
};

export default reduxForm({ form: "videoForm", validate })(VideoForm);
