import React from "react";
import { Form, Select } from "antd";
import "./Checkbox.scss";

const MultipleSelect = ({
  checked,
  input,
  label,
  size,
  meta,
  onChange,
  options,
  suffix,
  placeholder,
  defaultValue,
  ...rest
}) => {
  const style = meta.touched && meta.error ? { color: "red" } : null;
  return (
    <Form.Item
      className={`teapp-select-wrapper ${
        size ? "teapp-select-wrapper-" + size : null
      }`}
      {...rest}
    >
      {label ? <label>{label}:</label> : null}
      <Select
        mode="multiple"
        style={style}
        dropdownMatchSelectWidth={false}
        placeholder={placeholder}
        defaultValue={defaultValue}
      >
        {options.map((o) => {
          return (
            <Select.Option key={`${o.name}-${o.id}`}>
              {o.name} ({o.id})
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default MultipleSelect;
