import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { getEvents, getHiddenEvents } from "../../actions/eventsActions";
import { getVideos, getHiddenVideos } from "../../actions/videosActions";
import { getSlides } from "../../actions/slidesActions";
import { getPackets } from "../../actions/packetsActions";
import { getConfig } from "../../actions/appConfigActions";
import { getGiftSettings } from "../../actions/giftsActions";
import Wrapper from "../wrappers/Wrapper";
import FutureEvents from "./events/FutureEvents";
import AvailableVideos from "./videos/AvailableVideos";
import BirthdayChangeInform from "./BirthdayChangeInform";
import SlickSlider from "./slider/SlickSlider";
import { Skeleton, message } from "antd";
import {
  reverseVodEventsOrder,
  vodText,
  repertoireText,
  vodTextEn,
  repertoireTextEn,
  giftText,
  giftTextEn,
  packetText,
  packetTextEn,
  linkLoginIntegration,
} from "../../theme-variables";
import AcceptTos from "./AcceptTos";
import { withTranslation } from "react-i18next";
import FestivalEvents from "./festival/FestivalEvents";
import AddressChangeInform from "./AddressChangeInform";
import TlChangeInform from "./TlChangeInform";
import GiftBlock from "./gifts/GiftBlock";
import PacketBlock from "./packets/PacketBlock";
import ActivateCodeFormWithoutRedux from "../pages/node/ActivateCodeFormWithoutRedux";

class Dashboard extends React.Component {
  state = { currentLanguage: "pl" };
  componentDidMount() {
    this.props.getEvents();
    this.props.getHiddenEvents();
    this.props.getHiddenVideos();
    this.props.getVideos();
    this.props.getSlides();
    this.props.getConfig(false);
    this.props.getPackets();
    this.props.getGiftSettings();

    if (this.props.location.hash === "#code_activated") {
      setTimeout(() => {
        message.success(this.props.t("userpage.code_activated_short"));
      }, 3000);
    }
  }
  renderVod(videos, vodFilters, show) {
    if (show)
      return (
        <>
          <h2>
            {this.props.i18n.language === "pl"
              ? vodText || this.props.t("dashboard.vod_title")
              : vodTextEn || this.props.t("dashboard.vod_title")}
          </h2>
          <AvailableVideos allowFilters={vodFilters} videos={videos} />
          <br />
        </>
      );
  }
  renderRepertoire(events, show) {
    const eventsAfterNow = events
      .filter((event) => {
        const dateFrom = moment(
          moment(event.date_from).format("YYYY-MM-DD") + " " + event.time_from,
          "YYYY-MM-DD HH:mm"
        );
        return dateFrom.isAfter(moment());
      })
      .sort((a, b) => {
        const dateFromA = moment(
          moment(a.date_from).format("YYYY-MM-DD") + " " + a.time_from,
          "YYYY-MM-DD HH:mm"
        );
        const dateFromB = moment(
          moment(b.date_from).format("YYYY-MM-DD") + " " + b.time_from,
          "YYYY-MM-DD HH:mm"
        );
        return dateFromA.isBefore(dateFromB);
      });
    const eventsBeforeNow = events.filter((event) => {
      const dateFrom = moment(
        moment(event.date_from).format("YYYY-MM-DD") + " " + event.time_from,
        "YYYY-MM-DD HH:mm"
      );
      return dateFrom.isBefore(moment());
    });
    if (show)
      return (
        <>
          <h2>
            {this.props.i18n.language === "pl"
              ? repertoireText || this.props.t("dashboard.repertoire_title")
              : repertoireTextEn || this.props.t("dashboard.repertoire_title")}
          </h2>
          <FutureEvents events={[...eventsAfterNow, ...eventsBeforeNow]} />
          <br />
        </>
      );
  }
  renderFestival(events, show) {
    const {
      festival_title,
      festival_title_en,
      festival_short_dates,
      festival_show_finished,
      festival_reverse_finished,
    } = this.props.config;
    if (show)
      return (
        <>
          <h2>
            {this.props.i18n.language === "pl"
              ? festival_title
              : festival_title_en}
          </h2>
          <FestivalEvents
            shortDates={
              festival_short_dates === 1 || festival_short_dates === true
            }
            showFinished={
              festival_show_finished === 1 || festival_show_finished === true
            }
            reverseFinished={
              festival_reverse_finished === 1 ||
              festival_reverse_finished === true
            }
            events={events}
          />
          <br />
        </>
      );
  }
  renderPacketBlock() {
    const { packets } = this.props.packets;
    if (!packets.length) return null;

    return (
      <div id="pakiety">
        <h2>
          {this.props.i18n.language === "pl"
            ? packetText || this.props.t("dashboard.packet_title")
            : packetTextEn || this.props.t("dashboard.packet_title")}
        </h2>
        <PacketBlock packets={packets} />
      </div>
    );
  }
  renderGiftBlock() {
    const { any_gift_available, gift_expiration } = this.props.giftConfig;
    if (!any_gift_available || moment(gift_expiration).isBefore(moment()))
      return null;

    return (
      <div id="prezent">
        <h2>
          {this.props.i18n.language === "pl"
            ? giftText || this.props.t("dashboard.gift_title")
            : giftTextEn || this.props.t("dashboard.gift_title")}
        </h2>
        <GiftBlock
          config={this.props.giftConfig}
          language={this.props.i18n.language}
        />
      </div>
    );
  }
  renderCodeForm() {
    const { code_form_in_dashboard } = this.props.config;
    if (!code_form_in_dashboard) return null;

    return (
      <div id="kody" style={{ marginTop: 50 }}>
        <h2>
          {this.props.i18n.language === "pl"
            ? this.props.t("dashboard.code_form")
            : this.props.t("dashboard.code_form")}
        </h2>
        <ActivateCodeFormWithoutRedux
          userId={this.props.userId}
          token={this.props.token}
        />
      </div>
    );
  }
  renderHiddenContent() {
    const { hidden_events } = this.props.events;
    const { hidden_videos } = this.props.videos;

    if (hidden_events.length || hidden_videos.length) {
      return (
        <>
          <h2>Ukryte treści</h2>
          {hidden_events.length ? (
            <FestivalEvents
              shortDates={false}
              showFinished={false}
              reverseFinished={false}
              events={hidden_events}
            />
          ) : null}
          <br />
          {hidden_videos.length ? (
            <AvailableVideos
              allowFilters={false}
              videos={hidden_videos}
              forceRender={true}
            />
          ) : null}
        </>
      );
    }
  }
  render() {
    const { events } = this.props.events;
    const { videos } = this.props.videos;
    const repertoireEvents = events.filter(
      (event) => event.is_festival === false || event.is_festival === 0
    );
    const festivalEvents = events.filter(
      (event) => event.is_festival === true || event.is_festival === 1
    );
    const {
      view_slider,
      view_eventslist,
      view_vod,
      view_festival,
      vod_filters,
    } = this.props.config;
    if (!this.props.loadingConfig)
      return (
        <Wrapper fullWidth={true}>
          {view_slider ? <SlickSlider slides={this.props.slides} /> : null}
          {linkLoginIntegration ? <TlChangeInform /> : null}
          <BirthdayChangeInform />
          {/* <AddressChangeInform /> */}
          <AcceptTos />
          <div className="content-wrapper">
            <div className="content">
              {this.renderFestival(festivalEvents, view_festival)}
              {this.renderHiddenContent()}
              {reverseVodEventsOrder ? (
                <>
                  {this.renderVod(videos, vod_filters, view_vod)}
                  {this.renderRepertoire(repertoireEvents, view_eventslist)}
                </>
              ) : (
                <>
                  {this.renderRepertoire(repertoireEvents, view_eventslist)}
                  {this.renderVod(videos, vod_filters, view_vod)}
                </>
              )}
              {this.renderGiftBlock()}
              {this.renderPacketBlock()}

              {this.renderCodeForm()}
            </div>
          </div>
        </Wrapper>
      );
    else
      return (
        <Wrapper>
          <Skeleton />
        </Wrapper>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    videos: state.videos,
    packets: state.packets,
    token: state.auth.token,
    slides: state.slides,
    config: state.appConfig.config,
    loadingConfig: state.appConfig.loading,
    birthday: state.auth.user ? state.auth.user.birthday : null,
    giftConfig: state.gifts.config,
    userId: state.auth.user ? state.auth.user.id : null,
  };
};

export default connect(mapStateToProps, {
  getEvents,
  getHiddenEvents,
  getHiddenVideos,
  getVideos,
  getSlides,
  getPackets,
  getConfig,
  getGiftSettings,
})(withTranslation()(Dashboard));
