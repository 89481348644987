import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert, Button } from "antd";
import moment from "moment";
import { signOutUser } from "../../actions/authActions";
import { useTranslation } from "react-i18next";
import vod from "../../apis/vod";

const TlChangeInform = (props) => {
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    vod
      .get(`/api/users/${props.userId}`, {
        headers: {
          "x-auth-token": props.token,
        },
      })
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => {
        // message.error("Wystąpił błąd: " + err);
      });
  }, []);

  if (
    user &&
    user.provider === "teapp" &&
    moment(user.updated_at_tl).isAfter(moment(user.last_login))
  )
    return (
      <Alert
        className="ant-alert-warning"
        message={t("const.warning")}
        description={
          <>
            {t("tl.updated")}
            <br />
            <br />
            <Button onClick={props.signOutUser} type="primary">
              {t("nav.logout")}
            </Button>
          </>
        }
        type="warning"
        showIcon
        style={{ margin: "0 auto", maxWidth: 2320, marginBottom: 30 }}
      />
    );
  else return null;
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.user?.id,
  };
};

export default connect(mapStateToProps, { signOutUser })(TlChangeInform);
