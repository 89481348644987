import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  List,
  Card,
  Table,
  Tag,
  DatePicker,
  Button,
  Checkbox,
  Select,
  Space,
  Input,
  Skeleton,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { makeField } from "../../../../form-components/makeComponent";
import {
  renderTagByPaymentStatus,
  getDataSourceForPaymentList,
} from "../../../../../helpers/productUtilities";
import { withTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

class PaymentList extends React.Component {
  state = {
    showVideo: true,
    showEvent: true,
    eventSelect: undefined,
    videoSelect: undefined,
    dateFrom: null,
    dateTo: null,
    searchText: "",
    searchedColumn: "",
    paymentCsvData: [],
    dataSource: [],
    loadingStatistics: false,
    refunds: null,
    status: undefined,
  };
  onSubmit = (formValues) => {
    this.setState({
      dateFrom: formValues.date_from,
      dateTo: formValues.date_to,
      status: formValues.status,
      loadingStatistics: true,
    });
    this.props.onSubmit(formValues);
  };
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Szukaj...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 250, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 110 }}
          >
            Szukaj
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 110 }}
          >
            Wyczyść
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (v, r) => {
      if (dataIndex === "user") {
        if (
          r.firstname.toString().toLowerCase().includes(v.toLowerCase()) ||
          r.lastname.toString().toLowerCase().includes(v.toLowerCase()) ||
          r.email.toString().toLowerCase().includes(v.toLowerCase())
        )
          return true;
        else return "";
      }
      return r[dataIndex]
        ? r[dataIndex].toString().toLowerCase().includes(v.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  onDateRangeChange = (value, dateString) => {
    if (value !== null) {
      this.props.change("date_from", value[0].format("YYYY-MM-DD"));
      this.props.change("date_to", value[1].format("YYYY-MM-DD"));
    } else {
      this.props.change("date_from", null);
      this.props.change("date_to", null);
    }
  };
  countStatistics = (orders) => {
    const incomeWithRefunds =
      orders
        .map((o) => {
          if (
            o.status === "COMPLETED" ||
            (o.status === "REFUNDED" &&
              moment(o.updated_at).isAfter(this.state.dateTo))
          )
            return Number.parseFloat(o.amount);
          return 0;
        })
        .reduce((a, b) => a + b, 0) -
      (Number.parseFloat(this.props.eventRefunds) || 0) -
      (Number.parseFloat(this.props.videoRefunds) || 0);
    let statistics = [
      {
        key: "Liczba złożonych zamówień",
        value: orders.length,
      },
      {
        key: "Liczba zrealizowanych zamówień",
        value: orders.filter((o) => o.status === "COMPLETED").length,
      },
      {
        key: "Liczba zrealizowanych, płatnych zamówień",
        value: orders.filter((o) => o.status === "COMPLETED" && o.amount > 0)
          .length,
      },
      {
        key: "Liczba wykorzystanych zamówień",
        value: orders.filter((o) => o.used === 1).length,
      },
    ];
    if (this.state.status === "COMPLETED" || this.state.status === undefined) {
      statistics = [
        ...statistics,
        {
          key: "Wartość sprzedanych biletów (z uwzględnieniem zwrotów)",
          value:
            orders
              .map((o) => {
                if (o.status === "COMPLETED")
                  return Number.parseFloat(o.amount);
                return 0;
              })
              .reduce((a, b) => a + b, 0) + " zł",
        },
        {
          key: "Dochód",
          value: incomeWithRefunds + " zł",
        },
        {
          key: "Suma zwrotów w danym zakresie",
          value:
            (Number.parseFloat(this.props.eventRefunds) || 0) +
            (Number.parseFloat(this.props.videoRefunds) || 0) +
            " zł",
        },
      ];
    }
    return statistics;
  };
  onVideoChange = (v) => {
    let dataSource = getDataSourceForPaymentList(
      this.props.orders,
      this.props.videoOrders,
      this.props.t,
      v ? false : true,
      true
    );
    dataSource = dataSource.filter((o) => o.video_id === v);
    this.setState({
      paymentCsvData: this.generateCsvData(dataSource),
      eventSelect: undefined,
      videoSelect: v,
      showVideo: true,
      showEvent: v ? false : true,
    });
  };
  onEventChange = (v) => {
    let dataSource = getDataSourceForPaymentList(
      this.props.orders,
      this.props.videoOrders,
      this.props.t,
      true,
      v ? false : true
    );
    dataSource = dataSource.filter((o) => o.event_id === v);
    this.setState({
      paymentCsvData: this.generateCsvData(dataSource),
      videoSelect: undefined,
      eventSelect: v,
      showVideo: v ? false : true,
      showEvent: true,
    });
  };
  generateCsvData = (dataSource) => {
    const { dateFrom, dateTo } = this.state;
    const dateRange =
      dateFrom && dateTo
        ? dateFrom !== dateTo
          ? `${moment(dateFrom).format("DD.MM.YYYY")} - ${moment(dateTo).format(
              "DD.MM.YYYY"
            )}`
          : moment(dateFrom).format("DD.MM.YYYY")
        : moment().format("DD.MM.YYYY");

    let selectedProduct = null;
    if (this.state.videoSelect) {
      const { title } = this.props.videos.find(
        (v) => v.id === Number.parseInt(this.state.videoSelect)
      );
      selectedProduct = title;
    } else if (this.state.eventSelect) {
      const { title, date_from } = this.props.events.find(
        (e) => e.id === Number.parseInt(this.state.eventSelect)
      );
      if (title && date_from)
        selectedProduct = `${title} ${moment(date_from).format("D.MM")}`;
    }

    let csvData = [
      selectedProduct ? ["Tytuł", selectedProduct] : [],
      ["Zakres dat", dateRange],
      [
        "Liczba zrealizowanych zamówień",
        "Liczba zrealizowanych, płatnych zamówień",
        "Liczba wykorzystanych zamówień",
        "Wartość sprzedanych biletów (z uwzględnieniem zwrotów) [zł]",
        "Dochód",
        "Suma zwrotów w danym zakresie",
      ],
      [
        dataSource.filter((o) => o.status === "COMPLETED").length,
        dataSource.filter((o) => o.status === "COMPLETED" && o.amount > 0)
          .length,
        dataSource.filter((o) => o.used === 1).length,
        dataSource
          .map((o) => {
            if (o.status === "COMPLETED") return Number.parseFloat(o.amount);
            return 0;
          })
          .reduce((a, b) => a + b, 0),
        dataSource
          .map((o) => {
            if (
              o.status === "COMPLETED" ||
              (o.status === "REFUNDED" &&
                moment(o.updated_at).isAfter(this.state.dateTo))
            )
              return Number.parseFloat(o.amount);
            return 0;
          })
          .reduce((a, b) => a + b, 0) -
          (Number.parseFloat(this.props.eventRefunds) || 0) -
          (Number.parseFloat(this.props.videoRefunds) || 0),
        (Number.parseFloat(this.props.eventRefunds) || 0) +
          (Number.parseFloat(this.props.videoRefunds) || 0),
      ],
      [],
      [],
      ["Lista płatności"],
      [
        "Imię i nazwisko",
        "Email",
        "Zawartość",
        "Tytuł",
        "Czy wykorzystano zamówienie",
        "Numer zamówienia w systemie VOD",
        "Numer zamówienia w systemie płatności",
        "Cena",
        "Data utworzenia",
        "Godzina utworzenia",
        "Kraj",
        // "Adres IP",
        "Status płatności",
      ],
    ];
    dataSource.forEach((r) => {
      csvData.push([
        r.firstname + " " + r.lastname,
        r.email,
        r.type === "event" ? "wydarzenie" : "wideo",
        r.desc,
        r.used ? "Tak" : "Nie",
        r.orderId,
        r.extOrderId,
        parseFloat(r.amount).toFixed(2).toString().replace(".", ","),
        moment(r.created_at).format("YYYY-MM-DD"),
        moment(r.created_at).format("HH:mm"),
        r.country,
        // r.customer_ip,
        r.translatedStatus,
      ]);
    });
    return csvData;
  };
  handlePaymentsTableChange = (pagination, filters, sorter, extra) => {
    this.setState({
      paymentCsvData: this.generateCsvData(extra.currentDataSource),
    });
  };
  componentDidMount = () => {
    const dataSource = getDataSourceForPaymentList(
      this.props.orders,
      this.props.videoOrders,
      this.props.t,
      this.state.showEvent,
      this.state.showVideo
    );
    this.setState({
      dataSource,
      paymentCsvData: this.generateCsvData(dataSource),
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.orders !== this.props.orders ||
      prevProps.videoOrders !== this.props.videoOrders ||
      prevState.showEvent !== this.state.showEvent ||
      prevState.showVideo !== this.state.showVideo
    ) {
      let dataSource = getDataSourceForPaymentList(
        this.props.orders,
        this.props.videoOrders,
        this.props.t,
        this.state.showEvent,
        this.state.showVideo
      );
      if (this.state.eventSelect)
        dataSource = dataSource.filter(
          (o) => o.event_id === this.state.eventSelect
        );
      if (this.state.videoSelect)
        dataSource = dataSource.filter(
          (o) => o.video_id === this.state.videoSelect
        );
      this.setState({
        dataSource,
        paymentCsvData: this.generateCsvData(dataSource),
        loadingStatistics: false,
      });
    }
  }
  render() {
    const { t } = this.props;
    const { dateFrom, dateTo, eventSelect, videoSelect } = this.state;
    let dataSource = this.state.dataSource;

    const dateRange =
      dateFrom && dateTo
        ? dateFrom !== dateTo
          ? `${moment(dateFrom).format("DD.MM.YYYY")} - ${moment(dateTo).format(
              "DD.MM.YYYY"
            )}`
          : moment(dateFrom).format("DD.MM.YYYY")
        : moment().format("DD.MM.YYYY");

    if (eventSelect)
      dataSource = dataSource.filter((o) => o.event_id === eventSelect);
    if (videoSelect)
      dataSource = dataSource.filter((o) => o.video_id === videoSelect);

    return (
      <Card title="Lista płatności" className="payment-list">
        <p>
          Domyślnie wyświetlają się płatności z aktualnego dnia. Aby wyświetlić
          starsze płatności, należy podać zakres dat. <br />
          Poniższa lista niedotyczy płatności za prezenty oraz bilety grupowe.
        </p>
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          className="payment-list-form"
        >
          <Field
            label="Zakres dat"
            name="rangepicker"
            component={makeField(DatePicker.RangePicker, "DD.MM.YYYY")}
            placeholder={["Od", "Do"]}
            hasFeedback
            onChange={this.onDateRangeChange}
            onFocus={(e) => e.preventDefault()}
            onBlur={(e) => e.preventDefault()}
          />
          <div>
            <label style={{ display: "inline-block" }}>
              Status zamówienia:
            </label>
            <Field name="status" component="select">
              <option value="">Wszystkie statusy</option>
              <option value="COMPLETED">Zakończone</option>
              <option value="CANCELED">Anulowane</option>
              <option value="REFUNDED">Zwrócone</option>
              <option value="PENDING">Przetwarzane</option>
            </Field>
          </div>
          <br />
          <Button type="primary" htmlType="submit">
            Szukaj
          </Button>
        </form>
        <br />
        <hr />
        <br />
        <h2>Podsumowanie dla: {dateRange}</h2>
        <h3>Dodatkowe filtry</h3>
        <label htmlFor="showVideo">
          <Checkbox
            id="showVideo"
            type="checkbox"
            name="showvideo"
            checked={this.state.showVideo}
            style={{ marginRight: "5px" }}
            onChange={(e) => this.setState({ showVideo: e.target.checked })}
          />
          płatności za wideo
        </label>
        <label htmlFor="showEvent" style={{ marginLeft: "15px" }}>
          <Checkbox
            id="showEvent"
            type="checkbox"
            name="showevent"
            checked={this.state.showEvent}
            style={{ marginRight: "5px" }}
            onChange={(e) => this.setState({ showEvent: e.target.checked })}
          />
          płatności za wydarzenia
        </label>
        <br />
        <br />
        <label>
          <span style={{ minWidth: 150, display: "inline-block" }}>
            Wybierz wydarzenie:
          </span>
          <Select
            id="eventSelect"
            defaultValue=""
            allowClear
            style={{ width: "100%", maxWidth: 400 }}
            showSearch
            dropdownMatchSelectWidth={false}
            value={this.state.eventSelect}
            onChange={this.onEventChange}
            optionFilterProp={"children"}
          >
            {this.props.events.map((e) => (
              <Select.Option key={e.id} value={e.id}>
                {e.title} {moment(e.date_from).format("DD.MM")}
                {e.date_to ? `-${moment(e.date_to).format("DD.MM")}` : ""}{" "}
                <Link target="_blank" to={`/wydarzenie/${e.slug || e.id}`}>
                  link
                </Link>
              </Select.Option>
            ))}
          </Select>
        </label>
        <br />
        <br />
        <label>
          <span style={{ minWidth: 150, display: "inline-block" }}>
            Wybierz wideo:
          </span>
          <Select
            id="videoSelect"
            defaultValue=""
            allowClear
            style={{ width: "100%", maxWidth: 400 }}
            showSearch
            dropdownMatchSelectWidth={false}
            value={this.state.videoSelect}
            onChange={this.onVideoChange}
            optionFilterProp={"children"}
          >
            {this.props.videos.map((e) => (
              <Select.Option key={e.id} value={e.id}>
                {e.title}{" "}
                <Link target="_blank" to={`/wideo/${e.slug || e.id}`}>
                  link
                </Link>
              </Select.Option>
            ))}
          </Select>
        </label>
        <br />
        <br />
        {!this.state.loadingStatistics ? (
          <List
            header="Podsumowanie z uwzględnieniem powyższych filtrów (domyślnie: dzisiaj, dla wszystkich wydarzeń i wideo)"
            itemLayout="horizontal"
            size="small"
            dataSource={this.countStatistics(dataSource)}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <p style={{ marginBottom: 0 }}>
                      <strong>{item.key}: </strong>
                      {item.value}
                    </p>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <Skeleton />
        )}
        <Table
          dataSource={dataSource}
          pagination={{ defaultPageSize: 20 }}
          onChange={this.handlePaymentsTableChange}
        >
          <Column
            title="Użytkownik"
            dataIndex="user"
            key="user"
            width="250px"
            sorter={(a, b) => a.lastname.localeCompare(b.lastname)}
            {...this.getColumnSearchProps("user")}
            render={(v, r) => {
              return this.state.searchedColumn === "user" ? (
                <Link to={`/konto/${r.userId}`} style={{ color: "black" }}>
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: "#ffc069",
                      padding: 0,
                    }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={`${r.firstname} ${r.lastname} ${r.email}`}
                  />
                </Link>
              ) : (
                <Link to={`/konto/${r.userId}`} style={{ color: "black" }}>
                  {r.firstname} {r.lastname}
                  <br />
                  {r.email}
                </Link>
              );
            }}
          />
          {/* <Column
            title="Kraj i adres IP"
            dataIndex="country"
            key="country"
            sorter={(a, b) => a.country.localeCompare(b.country)}
            {...this.getColumnSearchProps("country")}
            render={(v, r) => {
              return this.state.searchedColumn === "country" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {r.country ? (
                    <>
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: "#ffc069",
                          padding: 0,
                        }}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={r.country}
                      />
                      <img
                        alt={`Flaga ${r.country}`}
                        height="17"
                        style={{ margin: "0 5px" }}
                        src={`https://cdn.ipregistry.co/flags/twemoji/${r.country.toLowerCase()}.svg`}
                      />
                      ({r.customer_ip})
                    </>
                  ) : (
                    r.customer_ip
                  )}
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {r.country ? (
                    <>
                      {r.country}{" "}
                      <img
                        alt={`Flaga ${r.country}`}
                        height="22"
                        style={{ margin: "0 10px" }}
                        src={`https://cdn.ipregistry.co/flags/twemoji/${r.country.toLowerCase()}.svg`}
                      />
                      ({r.customer_ip})
                    </>
                  ) : (
                    r.customer_ip
                  )}
                </div>
              );
            }}
          /> */}
          <Column title="Opis" dataIndex="desc" key="Opis" />
          <Column
            title="Czy wykorzystano?"
            dataIndex="used"
            key="used"
            sorter={(a, b) => a.views - b.views}
            render={(v, r) => {
              return (
                <>
                  <Tag color={r.used ? "green" : "volcano"}>
                    <strong>Liczba wyświetleń: </strong>
                    {r.views || 0}
                  </Tag>
                </>
              );
            }}
          />
          <Column
            title="Nr zamówienia"
            dataIndex="extOrderId"
            key="extOrderId"
            sorter={(a, b) => a.extOrderId.localeCompare(b.extOrderId)}
          />
          <Column
            title="Nr zamówienia w PayU"
            dataIndex="orderId"
            key="orderId"
            sorter={(a, b) => a.orderId.localeCompare(b.orderId)}
          />
          <Column
            title="Cena"
            dataIndex="amount"
            key="amount"
            sorter={(a, b) => a.amount - b.amount}
            render={(v) => `${v} zł`}
          />
          <Column
            title="Utworzono"
            dataIndex="created_at"
            key="created_at"
            sorter={(a, b) =>
              moment(a.created_at).unix() - moment(b.created_at).unix()
            }
            render={(v) => moment(v).format("H:mm, DD.MM.YYYY")}
          />
          <Column
            title="Zaktualizowano"
            dataIndex="updated_at"
            key="updated_at"
            sorter={(a, b) =>
              moment(a.updated_at).unix() - moment(b.updated_at).unix()
            }
            render={(v) => moment(v).format("H:mm, DD.MM.YYYY")}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(v) => {
              return renderTagByPaymentStatus(v, t);
            }}
          />
        </Table>

        <Button type="primary">
          <CSVLink
            data={this.state.paymentCsvData}
            separator={";"}
            filename={`${dateRange} - podsumowanie płatności.csv`}
          >
            Eksportuj do CSV
          </CSVLink>
        </Button>
      </Card>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (moment(formValues.date_from).isAfter(moment(formValues.date_to)))
    errors.rangepicker = "Niepoprawna data";

  return errors;
};

export default reduxForm({ form: "paymentListForm", validate })(
  withTranslation()(PaymentList)
);
